<template>
	<p
		class="d-inline-block mb-0"
		:class="`text-${titleSize} font-weight-${this.titleWeight} ${titleColor}--text`"
		style="max-width: 90vw;"
	>
		<v-icon
			v-if="icon"
			:small="iconSize == 'small'"
			:large="iconSize == 'large'"
			left
			:color="iconColor"
			class="mr-1"
		>{{mdi}}</v-icon>

		<slot></slot>

		<slot name="button"></slot>
	</p>
</template>

<script>
	export default {
		props: {
			titleWeight: {
				type: String,
				default: 'regular'
			},
			titleSize: {
				type: String,
				default: 'h6'
			},
			titleColor: {
				type: String,
				default: 'a'
			},
			icon: Boolean,
			mdi: String,
			iconColor: String,
			iconSize: String
		},
		data() {
			return {};
		},
	}
</script>
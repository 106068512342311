<template>
	<div>
		<v-row class="mb-12 pa-0 ma-0" style="width: 100%; position: absolute; top: 0px; left: 0px;">

			<v-img :src="$vuetify.theme.dark ? `${bgUrl}` : `${bgUrlLight}`" :lazy-src="$vuetify.theme.dark ? `${bgUrlLazy}`  : `${bgUrlLightLazy}`" :height="`${height}`" width="100vw" style="position: absolute; top: 0; left: 0;" :style="'filter: blur(' + `${imgBlur}` + ');'"></v-img>
			<v-sheet :color="overlayColor" :height="`${height}`" width="100vw" style="position: absolute; top: 0; left: 0;" :style="'opacity:' + `${overlayOpacity}` + ';'"></v-sheet>
			<v-sheet color="transparent" :height="`${height}`" width="100vw" style="z-index: 0;">

				<v-row>
					<v-col class="px-8 pt-10 pb-0" cols="12">
						<Title class="float-left mt-3 pl-3 mb-0" :titleWeight="titleWeight" :titleSize="baliseTitle" :titleColor="titleColor">{{ title }}</Title>
						<h1 class="white--text float-right font-weight-thin mr-8 mt-2 mb-0 pb-0" v-if="$vuetify.breakpoint.smAndUp">{{ currentTime }}</h1>
					</v-col>

					<v-col cols="12" sm="12" md="12" lg="12" xl="12">
						<h3 class="white--text float-left font-weight-light font-italic mt-0 pt-0 ml-8">
							{{subTitle}}
							<br>
							{{subTitle2}}
						</h3>
					</v-col>
				</v-row>

				<Transition transitionPosition="bottom" :transitionRotate="transitionRotate" />

			</v-sheet>

		</v-row>

		<!-- v-if="$vuetify.breakpoint.width > 450" -->
		<v-sheet color="transparent" :height="`${offset}`" class="ma-0 pa-0"></v-sheet>

	</div>

</template>


<script>
	import Transition from '@/components/graphics/transition';
	import Title from '@/components/graphics/title';

	export default {
		props: {
			id: {
				type: String
			},

			bgUrl: {
				type: String,
				default: 'https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg',
			},
			bgUrlLight: {
				type: String,
				default: 'https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg',
			},

			bgUrlLazy: {
				type: String,
				default: 'https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg',
			},
			bgUrlLightLazy: {
				type: String,
				default: 'https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg',
			},
			imgBlur: {
				type: String,
				default: '0px'
			},

			offset: {
				type: String,
				default: '10vh'
			},
			height: {
				type: String,
				default: '45vh'
			},

			overlayOpacity: {
				type: String,
				default: '0.3'
			},
			overlayColor: {
				type: String,
				default: 'black'
			},
			
			transitionRotate: {
				type: String,
				default: '180'
			},

			// h1, h2, h3, h4, h5, h6
			baliseTitle: {
				type: String,
				default: 'h2'
			},
			title: {
				type: String,
				default: 'Default Title'
			},
			// thin, light, regular, medium, bold, black
			titleWeight: {
				type: String,
				default: 'thin'
			},
			// white, black, primary
			titleColor: {
				type: String,
				default: 'white'
			},

			subTitle: {
				type: String
			},
			subTitle2: {
				type: String
			},

		},
		components: {
			Title,
			Transition,
		},

		data() {
			return {
				message: "Current Time:",
				currentTime: null
			};
		},


		methods: {
			updateCurrentTime() {
				this.currentTime = this.$moment().format("HH:mm:ss");
			}
		},

		created() {
			this.currentTime = this.$moment().format("HH:mm:ss");
			setInterval(() => this.updateCurrentTime(), 1 * 1000);
		},
	}

</script>
